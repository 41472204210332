<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    :stroke-width="strokeWidth"
    :aria-labelledby="label"
    role="presentation"
  >
    <title :id="label" lang="en">{{ label }}</title>
    <g :fill="color">
      <template v-if="icon">
        <component :is="icon"></component>
      </template>
      <template v-else>
        <slot :stroke-width="strokeWidth" />
      </template>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    size: {
      type: [Number, String],
      default: 18
    },
    color: {
      type: String,
      default: 'currentColor'
    },
    icon: {
      type: Object,
      default: null
    },
    strokeWidth: {
      type: Number,
      default: 1
    }
  }
}
</script>
