<template>
  <div
    class="component-location-item"
    :class="{ active: isActive, 'is-clickable': isClickable }"
  >
    <div
      class="location-image"
      :style="{ backgroundImage: 'url(' + image + ')' }"
    />
    <div class="location-info" :class="{ 'text-ellipsis': textEllipsis }">
      <p class="location-data name">
        {{ name }}
      </p>
      <p class="location-data address">
        {{ address }}
      </p>
      <p class="location-data price">
        <template v-if="cost > 0">{{ $n(cost, 'currency') }} p/u</template>
        <template
          v-else-if="name === 'ALCMARIA VICTRIX - VOETBALVELDEN (KUNSTGRAS)'"
        >
          <div>Half veld: €10 per keer</div>
          <div>Heel veld: €20,- per keer</div>
        </template>
        <template v-else>Gratis</template>
      </p>
      <p class="location-data entrance">
        {{ entranceInstructions }}
      </p>
    </div>
    <div class="clearfix" />
    <i v-if="isClickable" class="fas fa-chevron-right chevron-right" />
    <hr class="my-3.5 border-gray-300 no-bottom-margin" />
  </div>
</template>

<script>
export default {
  name: 'EventItem',
  props: {
    name: {
      type: String,
      required: true
    },
    entranceInstructions: {
      type: String,
      required: false,
      default: ''
    },
    address: {
      type: String,
      required: true
    },
    cost: {
      type: String,
      required: true
    },
    image: {
      type: String,
      required: false,
      default: ''
    },
    isActive: {
      type: Boolean,
      required: false
    },
    isClickable: {
      type: Boolean,
      required: false,
      default: false
    },
    textEllipsis: {
      type: Boolean,
      required: false,
      default: true
    },
    description: {
      type: String,
      required: false,
      default: ''
    },
    coordinates: {
      type: Object,
      required: false,
      default: () => ({})
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables';
.component-location-item {
  position: relative;
  padding-top: 15px;
}
.component-location-item.is-clickable {
  cursor: pointer;
}
.component-location-item.active {
  background: $greyLight;
}
.component-location-item .location-image {
  width: 110px;
  height: 80px;
  float: left;
  position: relative;
  background-size: cover;
  background-position: center;
}
.component-location-item .location-info {
  float: right;
  width: calc(100% - 125px);
  position: relative;
  padding-right: 15px;
}
.component-location-item .location-info .location-data {
  font-size: 0.938rem;
  width: 100%;
  margin-bottom: 0;
  line-height: 20px;
}
.component-location-item .location-info .location-data.name {
  font-weight: 600;
}
.component-location-item .location-info.text-ellipsis {
  height: 80px;
  overflow: hidden;
}
.component-location-item .location-info.text-ellipsis .location-data {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.component-location-item i.chevron-right {
  color: $ipGreen;
  font-size: 1.25em;
  right: 5px;
  position: absolute;
  @include transform(translateY(-50%));
  top: 50%;
}
</style>
