<template>
  <div class="screen-organize-location-details screen-organize">
    <app-bar :title="pageTitle" :show-back-button="true" />
    <spinner-loader v-if="locationStatus === 'loading'" />
    <template v-else>
      <div class="container">
        <h2 class="page-title">Zaal details</h2>
        <template v-if="locationView">
          <location-item
            :name="locationView.name"
            :address="locationView.address"
            :cost="locationView.cost"
            :image="locationView.image"
            :text-ellipsis="false"
            :entrance-instructions="locationView.entranceInstructions"
          />

          <div class="margin-top-15 wrapper-description">
            <nl2br
              v-if="locationView.description != null"
              tag="p"
              :text="locationView.description"
            />
            <a
              href="#"
              class="btn btn-green btn-block margin-top-30"
              @click.prevent="saveNewLocation"
            >
              reserveer nu
            </a>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import LocationItem from '@/components/LocationItem'
import SpinnerLoader from '@/components/SpinnerLoader'
import {
  EVENT_UPDATE_LOCATION,
  LOCATION_VIEW
  // LOCATION_MAKE_RESERVATION
} from '@/store/actions/location'
import Nl2br from 'vue-nl2br'
import { mapState } from 'vuex'

export default {
  name: 'OrganizeLocationDetails',
  components: {
    SpinnerLoader,
    AppBar,
    Nl2br,
    LocationItem
  },
  data() {
    const isForEdit = this.$route.params && this.$route.params.id !== undefined
    return {
      isForEdit,
      eventID: this.$route.params.id || null,
      pageTitle: isForEdit ? 'Event aanpassen' : 'Nieuwe activiteit 4 / 5'
    }
  },
  computed: {
    ...mapState({
      eventItemLocationID: (state) => state.event.eventItem.locationID,
      locationStatus: (state) => state.location.status,
      locationView: (state) => state.location.locationView
    })
  },
  mounted() {
    if (!this.hasAccessToScreen()) {
      if (this.isForEdit) {
        this.$router.push({
          path: `/event-edit/${this.eventID}/load`,
          query: {
            returnPage: `location-details/${this.$route.params.locationID}`
          }
        })
      } else {
        this.$router.push('/organize-location-list')
      }
      return
    }

    if (this.isForEdit) {
      this.$store.dispatch(LOCATION_VIEW, this.$route.params.locationID)
    } else {
      this.$store.dispatch(
        LOCATION_VIEW,
        this.$store.state.event.eventItem.locationID
      )
    }
  },
  methods: {
    hasAccessToScreen() {
      let hasAccess = false
      if (this.isForEdit) {
        if (
          this.$store.state.event.eventEdit &&
          !isNaN(this.$store.state.event.eventEdit.id) &&
          !isNaN(this.$store.state.event.eventEdit.locationID)
        ) {
          hasAccess = true
        }
      } else {
        if (this.$store.state.event.eventItem.locationID !== null) {
          hasAccess = true
        }
      }
      return hasAccess
    },
    saveNewLocation() {
      if (this.isForEdit) {
        this.$store
          .dispatch(EVENT_UPDATE_LOCATION, {
            eventID: this.$route.params.id,
            locationID: this.$route.params.locationID
          })
          .then((res) => {
            if (res) {
              this.$router.push(`/event-view/${this.$route.params.id}`)
            } else {
              alert('Locatie kon niet worden aangepast')
            }
          })
      } else {
        this.$router.push('/events/new/info')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';

.wrapper-location-list {
  padding-bottom: 75px;
}
</style>
