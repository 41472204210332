<template>
  <div class="screen-organize">
    <app-bar
      :title="
        event.groupId
          ? 'Nieuwe groepsactiviteit 4 / 5'
          : 'Nieuwe activiteit 6 / 7'
      "
      :show-back-button="true"
      :back-button-path="`/events/new/info${origin ? `?origin=${origin}` : ''}`"
      :show-profile-button="false"
    />
    <div class="container">
      <!-- Visibility -->
      <h1>Wie kan je activiteit zien?</h1>

      <toggle
        v-model="event.publicEvent"
        title="Iedereen kan deze activiteit zien op Inviplay"
        @toggle="
          (val) => {
            event.publicEvent = val
          }
        "
      />

      <!-- Add to group -->

      <div class="flex align-middle items-center mt-8 mb-4">
        <h2 class="my-0">Groepspagina</h2>
        <span
          v-if="!event.groupId"
          class="ml-2 inline-block"
          @click="showGroupInfoModal = true"
        >
          <icon-base size="24" class="mt-0 text-green-600">
            <icon-info :stroke-width="2" />
          </icon-base>
        </span>
      </div>

      <template v-if="event.groupId">
        <p class="text-small">
          Je activiteit wordt toegevoegd aan je groepspagina
          <strong>{{ event.selectedGroupName }}</strong>
        </p>
      </template>

      <template
        v-else-if="!event.groupId && groupChoices && groupChoices.length"
      >
        <toggle
          v-model="addToExistingGroup"
          title="Voeg activiteit toe aan een van mijn bestaande groepen"
          class="my-4"
          @toggle="
            (val) => {
              addToExistingGroup = val
            }
          "
        />
      </template>

      <template
        v-if="
          addToExistingGroup &&
          !event.groupId &&
          groupChoices &&
          groupChoices.length
        "
      >
        <dropdown
          v-if="
            addToExistingGroup &&
            !event.groupId &&
            groupChoices &&
            groupChoices.length
          "
          v-model="event.selectedGroupId"
          :items="groupChoices"
          class="mb-4"
          empty-option="Kies een groep..."
          @on-select="updateSelectedGroup"
        />
      </template>
      <template
        v-else-if="!groupChoices || !groupChoices.length || !addToExistingGroup"
      >
        <p v-if="!groupChoices || !groupChoices.length" class="text-small mt-2">
          Je hebt nog geen groepen aangemaakt op Inviplay, geef hieronder de
          naam van je groep waar we jouw activiteit kunnen plaatsen.
        </p>
        <p v-else class="text-small mt-8">
          Geef hieronder de naam van je nieuwe groep waar we jouw activiteit
          kunnen plaatsen.
        </p>
        <input-field
          v-model="event.newGroupName"
          default-value="Naam van je groep"
          class="mb-4 mt-4"
          @input="updateNewGroupName"
        />
      </template>

      <!-- send invites -->
      <h2 class="mt-8">Uitnodigingen versturen</h2>
      <template
        v-if="
          !event.privateEvent ||
          (addToExistingGroup && (event.groupId || event.selectedGroupId))
        "
      >
        <toggle
          v-if="!event.privateEvent"
          v-model="event.sendGeneralInvites"
          :title="`Verstuur uitnodigingen naar iedereen bij jouw in de buurt die ${activity} volgt`"
          class="my-4"
          @toggle="
            (val) => {
              event.sendGeneralInvites = val
            }
          "
        />
        <toggle
          v-if="addToExistingGroup && (event.selectedGroupId || event.groupId)"
          v-model="event.sendGroupInvites"
          :title="`Verstuur uitnodigingen naar iedereen in de groep \'${event.selectedGroupName}\'`"
          class="my-4"
          @toggle="
            (val) => {
              event.sendGroupInvites = val
            }
          "
        />
      </template>
      <template v-else>
        <p class="text-sm my-4">
          Dit is geen openbare activiteit, er worden geen uitnodigingen
          verstuurd
        </p>
      </template>

      <button
        class="button button-primary w-full mt-5"
        :disabled="formInvalid"
        @click="goToNextStep"
      >
        Volgende
      </button>
    </div>
    <modal :show="showGroupInfoModal" @close="showGroupInfoModal = false">
      <template v-slot:header>Groepspagina's</template>
      <p class="text-sm">
        Heb je nog geen groepspagina? Dan wordt er na het aanmaken van jouw
        activiteit automatisch een nieuwe groepspagina aangemaakt waar jouw
        activiteit op staat. Een volgende keer kan je makkelijk in dit scherm
        nieuwe activiteiten toevoegen door hier jouw bestaande groepspagina te
        kiezen.
      </p>
      <p class="text-sm mt-4">
        Mensen kunnen lid worden van jouw groep en zo in contact komen met jou
        (en anderen) door een berichtje te sturen en zich opgeven voor de
        activiteiten.
      </p>
      <p class="text-sm mt-4">
        Als je een strippenkaart koppelt aan jouw activiteiten kunnen mensen hem
        hier direct aanschaffen.
      </p>
    </modal>
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import Dropdown from '@/components/Dropdown'
import Toggle from '@/components/Toggle'
import { mapState, mapGetters } from 'vuex'
import { EVENT_ITEM_UPDATE } from '@/store/actions/event'
import { ACTIVITY_LIST } from '@/store/actions/activity'
import { PARTICIPANT_GET_GROUPS_OWNED } from '@/store/actions/participant'
import InputField from '@/components/InputField'
import IconInfo from '@/components/icons/IconInfo'
import IconBase from '@/components/IconBase'
import Modal from '@/components/Modal'
// import RadioButton from '@/components/RadioButton'

export default {
  name: 'OrganizeInvite',
  components: {
    AppBar,
    Dropdown,
    Toggle,
    InputField,
    IconBase,
    IconInfo,
    Modal
  },
  data() {
    return {
      addToExistingGroup: true,
      event: {
        groupId: null,
        publicEvent: true,
        groupEvent: false,
        privateEvent: false,
        selectedGroupId: null,
        selectedGroupName: null,
        sendGeneralInvites: true,
        sendGroupInvites: false,
        newGroupName: null
      },
      origin: this.$route.query.origin,
      showGroupInfoModal: false
    }
  },
  computed: {
    ...mapState({
      eventItem: (state) => state.event.eventItem,
      ownGroups: (state) => state.participant.ownGroups,
      activityKeyValueList: (state) => state.activity.activityKeyValueList
    }),
    ...mapGetters(['authId']),
    groupsWatchable() {
      return this.ownGroups, this.event.selectedGroupId, Date.now()
    },
    formInvalid() {
      return (
        (!this.event.groupId &&
          !this.event.publicEvent &&
          !this.event.privateEvent) ||
        (this.addToExistingGroup &&
          !this.event.selectedGroupId &&
          !this.event.groupId) ||
        (!this.addToExistingGroup && !this.event.newGroupName)
      )
    },
    activity: function () {
      let sport
      if (this.activityKeyValueList.length > 0) {
        sport = this.activityKeyValueList.find(
          (a) => a.key === parseInt(this.event.activityID)
        )
      }
      return sport ? sport.value : undefined
    },
    groupChoices: function () {
      return this.ownGroups?.map((group) => {
        return { key: group.id.toString(), value: group.name }
      })
    }
  },
  watch: {
    'event.privateEvent'(privateEvent) {
      this.event.sendGeneralInvites = !privateEvent
    },
    'event.publicEvent'(publicEvent) {
      this.event.privateEvent = !publicEvent
    },
    'event.groupEvent'(groupEvent) {
      this.event.sendGroupInvites = groupEvent
      if (!groupEvent) {
        this.event.selectedGroupId = null
        this.event.selectedGroupName = null
      }
    },
    ownGroups(groups) {
      if (this.event.groupId) {
        const selectedGroup = groups.find(
          (group) => group.id === parseInt(this.event.groupId)
        )
        this.event.selectedGroupName = selectedGroup ? selectedGroup.name : null
        this.event.sendGroupInvites = true
      }
      if (!groups.length) {
        this.addToExistingGroup = false
      }
    },
    groupsWatchable() {
      if (!this.event.selectedGroupId && !this.event.groupId) {
        this.event.selectedGroupName = null
      } else if (
        this.ownGroups &&
        this.event.selectedGroupId &&
        !this.event.groupId
      ) {
        const selectedGroup = this.ownGroups.find(
          (group) => group.id === parseInt(this.event.selectedGroupId)
        )
        this.event.selectedGroupName = selectedGroup ? selectedGroup.name : null
        this.event.groupEvent = true
      }
    }
  },
  created: function () {
    if (!this.$store.state.event.eventItem.name) {
      this.$router.push(
        `/events/new/info${this.origin ? `?origin=${this.origin}` : ''}`
      )
    }
    this.event = { ...this.event, ...this.eventItem }
    this.$store.dispatch(PARTICIPANT_GET_GROUPS_OWNED, {
      participantId: this.authId
    })
    this.$store.dispatch(ACTIVITY_LIST)
  },
  mounted: function () {
    this.$store.dispatch(PARTICIPANT_GET_GROUPS_OWNED, {
      participantId: this.authId
    })
  },
  methods: {
    updateSelectedGroup(val) {
      this.event.selectedGroupId = val
      this.updateEventItem()
    },
    updateNewGroupName(val) {
      this.event.newGroupName = val
      this.updateEventItem()
    },
    updateEventItem() {
      this.$store.commit(EVENT_ITEM_UPDATE, this.event)
    },
    goToNextStep() {
      this.$store.commit(EVENT_ITEM_UPDATE, this.event)
      this.$router.push(
        `/events/new/costs${this.origin ? `?origin=${this.origin}` : ''}`
      )
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
</style>
