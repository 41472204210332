<template>
  <div class="screen-organize-location-options screen-organize">
    <app-bar :title="pageTitle" :show-back-button="true" />
    <div class="container">
      <h2 class="page-title my-4">Waar?</h2>

      <a
        href="#"
        class="btn btn-green btn-block mb-5 btn-round"
        @click.prevent="goToScreen('locationMap')"
      >
        kies een plek op kaart
      </a>

      <!-- <hr class="my-3.5 border-gray-300" />

      <h2 class="page-title">
        Of reserveer een gymzaal, sporthal of voetbalveld:
      </h2>

      <a
        href="#"
        class="btn btn-green btn-block margin-bottom-15"
        @click.prevent="goToScreen('locationList')"
      >
        sportlocaties Alkmaar
      </a>

      <p class="contact-inviplay-text">
        Een locatie of gemeente aanmelden? Stuur een mail naar
        <a :href="`mailto:contact@inviplay.nl`">contact@inviplay.nl</a>
      </p> -->
    </div>
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'

export default {
  name: 'OrganizeLocationOptions',
  components: {
    AppBar
  },
  data() {
    const isForEdit = this.$route.params && this.$route.params.id !== undefined
    return {
      isForEdit,
      eventID: this.$route.params.id || null,
      pageTitle: this.$store.state.event.eventItem.groupID
        ? 'Nieuwe groepsactiviteit 2 / 5'
        : isForEdit
        ? 'Event aanpassen'
        : 'Nieuwe activiteit 4 / 7'
    }
  },
  mounted() {
    if (!this.hasAccessToScreen()) {
      if (this.isForEdit) {
        this.$router.push({
          path: `/event-edit/${this.eventID}/load`,
          query: {
            returnPage: 'location-options'
          }
        })
      } else {
        this.$router.push('/events/new/date')
      }
    }
    this.groupId = this.$store.state.event.eventItem.groupID
  },
  methods: {
    hasAccessToScreen() {
      let hasAccess = false
      if (this.isForEdit) {
        if (
          this.$store.state.event.eventEdit &&
          !isNaN(this.$store.state.event.eventEdit.id)
        ) {
          hasAccess = true
        }
      } else {
        if (this.$store.state.event.eventItem.startDate !== null) {
          hasAccess = true
        }
      }
      return hasAccess
    },
    goToScreen(pageType) {
      let url = null
      switch (pageType) {
        case 'locationList':
          url = this.isForEdit
            ? `/event-edit/${this.eventID}/location-list`
            : '/organize-location-list'
          break
        case 'locationMap':
          url = this.isForEdit
            ? `/event-edit/${this.eventID}/location-map`
            : '/events/new/map'
          break
      }
      if (url) {
        this.$router.push(url)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';

.organize-instructions-text {
  font-size: 1.25rem;
}

.border-gray-300 {
  margin-top: 30px;
}

.contact-inviplay-text {
  margin-top: 20px;
  font-size: 1rem;
  font-style: italic;
}
</style>
