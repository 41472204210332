<template>
  <div class="screen-organize-info screen-organize">
    <app-bar
      :title="
        event.groupId
          ? 'Nieuwe groepsactiviteit 4 / 5'
          : 'Nieuwe activiteit 7 / 7'
      "
      :show-back-button="true"
      :back-button-path="`/events/new/invite${
        origin ? `?origin=${origin}` : ''
      }`"
      :show-profile-button="false"
    />
    <div class="container">
      <h1>Kosten</h1>
      <p class="text-sm">
        Wil je dat de deelnemers vooraf een bedrag betalen als ze zich
        aanmelden?
      </p>
      <div class="mt-3">
        <radio-button
          name="costs"
          label="Nee dit is een gratis activiteit"
          value="no"
          :selected="hasCosts"
          @change="setHasCosts"
        />
        <radio-button
          name="costs"
          label="Ja ik vraag een bedrag per deelnemer"
          value="yes"
          :selected="hasCosts"
          @change="setHasCosts"
        />
      </div>
      <template v-if="hasCosts === 'yes'">
        <template v-if="participantIsMerchant || paymentProvider === 'mollie'">
          <div
            v-if="paymentProvider === 'opp' && merchantIsPending"
            class="border rounded-lg border-yellow-600 p-4 mb-8 text-yellow-700 flex items-center"
          >
            <div class="mr-4 flex-initial">
              <icon-base size="32" class="bg-yellow-600 rounded-full p-1.5">
                <icon-clock :stroke-width="3" class="text-white" />
              </icon-base>
            </div>
            <p class="text-sm">
              Je bankrekening nummer wordt op dit moment geverifieerd, je
              ontvangt van ons een mail zodra dit is gebeurd. Doorgaans duurt
              dit 24 uur, mocht het langer duren stuur ons dan even een
              berichtje op contact@inviplay.nl. Je kunt je activiteit al wel
              aanmaken en deelnemers kunnen al wel betalen voor jouw activiteit.
              Zodra jouw ID is geverifieerd worden deze betalingen uiteraard
              gewoon aan je uitgekeerd.
            </p>
          </div>

          <div
            v-else-if="paymentProvider === 'opp' && merchantIsLiveAndVerified"
            class="border rounded-lg border-green-600 p-4 mb-8 text-green-700 flex items-center"
          >
            <div class="mr-4">
              <icon-base size="32" class="bg-green-600 rounded-full p-1.5">
                <icon-check :stroke-width="3" class="text-white" />
              </icon-base>
            </div>
            <p class="text-sm">
              Je bankrekening nummer is geverifieerd. Je wordt uitbetaald op
              <strong>{{ bankAccountNumber }}</strong>
              ten name van
              <strong>{{ bankAccountName }}</strong>
            </p>
          </div>

          <h2 class="mt-8">Kosten per persoon</h2>
          <input-field
            id="costs"
            v-model="event.cost"
            default-value="kosten € p.p. per keer"
            type="number"
            step="0.01"
            @input="formatCosts"
          />
          <div class="flex">
            <div class="text-sm flex-1">
              Kosten per deelnemer per activiteit inclusief
              {{ $n(fee, 'currency') }}
              <sup>*</sup>
              servicekosten
            </div>
            <div class="font-bold py-3 px-5">
              {{
                event.cost
                  ? $n(parseFloat(event.cost) + fee, 'currency')
                  : $n(0, 'currency')
              }}
            </div>
          </div>
          <div v-if="paymentProvider === 'mollie'" class="mt-6">
            <h2>Rekeningnummer</h2>
            <p class="text-sm">
              Geef de naam van de rekeninghouder en het IBAN nummer op of pas
              deze aan zodat wij jou snel kunnen uitbetalen
            </p>
            <input-field
              v-model="event.bankAccountName"
              class="mt-4"
              default-value="Naam rekeninghouder"
              @input="checkBankAccountName"
            />
            <div v-if="bankAccountNameError" class="text-red-800 mt-3 text-sm">
              De naam van de rekeninghouder moet minimaal 3 tekens bevatten.
            </div>
            <div class="relative">
              <input-field
                v-model="event.bankAccountNumber"
                default-value="IBAN nummer"
                class="uppercase mt-4 relative"
                @input="checkBankAccountNumber"
              />
              <i
                v-if="event.bankAccountNumber && !bankAccountNumberError"
                class="absolute top-4 right-4 text-green-600 fal fa-check"
              />
              <div
                v-if="bankAccountNumberError"
                class="text-red-800 mt-3 text-sm"
              >
                Dit geen correct IBAN nummer
              </div>
            </div>
          </div>
          <div class="mt-4"></div>
          <div class="text-xs text-gray-400 mt-2 px-0">
            <sup>*</sup>
            Als organisator maken we het jou makkelijk: je hoeft nooit meer
            achter je geld aan! En omdat deelnemers vooraf via Inviplay betalen
            is de kans op een no-show lager. Hiervoor maken wij wel extra kosten
            (o.a. voor ideal) die alleen jij (de organisator) ziet. Zegt iemand
            toch last-minute af dan zit jij niet met gemiste inkomsten! Jouw
            geld staat binnen een week na afloop van je event op je rekening.
          </div>
        </template>
        <template v-else>
          <id-check-component redirect-to="/events/new/costs" />
        </template>
      </template>
      <div class="form-group">
        <button
          v-if="
            hasCosts === 'no' ||
            participantIsMerchant ||
            paymentProvider === 'mollie'
          "
          :disabled="
            hasCosts === 'yes' &&
            (!event.cost ||
              (paymentProvider === 'mollie' &&
                (!event.bankAccountNumber ||
                  bankAccountNumberError ||
                  !event.bankAccountName ||
                  bankAccountNameError)))
          "
          class="button button-primary w-full mt-5"
          @click="checkAndGoToReady"
        >
          volgende
        </button>
      </div>
    </div>
    <modal :show="showMerchantModal" @close="closeMerchantModal">
      show feedback
    </modal>
    <spinner-loader v-if="eventLoading" />
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import SpinnerLoader from '@/components/SpinnerLoader'
import RadioButton from '@/components/RadioButton'
import InputField from '@/components/InputField'
import Modal from '@/components/Modal'
import { EVENT_STATUS_EMPTY, EVENT_ITEM_SET_COSTS } from '@/store/actions/event'
import { mapState } from 'vuex'
import {
  PARTICIPANT_OWN,
  PARTICIPANT_UPDATE_BANKACCOUNT
} from '@/store/actions/participant'
import { OPTION_GET } from '@/store/actions/option'
import { EVENT_ITEM_SET } from '../../store/actions/event'
import { PARTICIPANT_UPDATE_PHONENUMBER } from '../../store/actions/participant'
import config from '@/utils/config'
import IconBase from '@/components/IconBase'
import IconCheck from '@/components/icons/IconCheck'
import IconClock from '@/components/icons/IconClock'
import IdCheckComponent from '@/components/IdCheckComponent'

export default {
  name: 'OrganizeCosts',
  components: {
    AppBar,
    SpinnerLoader,
    RadioButton,
    InputField,
    IconClock,
    IconCheck,
    IconBase,
    Modal,
    IdCheckComponent
  },
  data() {
    return {
      hasCosts: 'no',
      event: {
        cost: 0,
        bankAccountNumber: null,
        bankAccountName: null
      },
      origin: this.$route.query.origin,
      loading: false,
      phoneNumberError: false,
      bankAccountNumberError: false,
      bankAccountNameError: false,
      phoneNumber: undefined,
      action: this.$route.query.action,
      showMerchantModal: false,
      paymentProvider: config.paymentProvider
    }
  },
  computed: {
    ...mapState({
      eventLoading: (state) => state.event.status === 'loading',
      fee: (state) => state.option.options.payments_fee,
      profile: (state) => state.participant.ownProfile,
      eventItem: (state) => state.event.eventItem
    }),
    participantIsMerchant() {
      return !!this.profile?.merchant?.merchant_uid
    },
    merchantIsLiveAndVerified() {
      return (
        this.profile.merchant?.compliance?.status === 'verified' &&
        this.profile.merchant?.status === 'live'
      )
    },
    merchantIsPending() {
      return (
        this.profile.merchant?.status === 'new' ||
        this.profile.merchant?.status === 'pending' ||
        this.profile.merchant?.compliance?.status === 'pending' ||
        this.profile.merchant?.compliance?.status === 'unverified'
      )
    },
    defaultBankAccount() {
      return this.profile?.merchant?.bank_accounts.find(
        (item) => item.is_default
      )
    },
    bankAccountNumber() {
      return this.defaultBankAccount?.account?.account_iban
    },
    bankAccountName() {
      return this.defaultBankAccount?.account?.account_name
    }
  },
  watch: {
    profile(profile) {
      if (profile?.phoneNumber && !this.phoneNumber) {
        this.phoneNumber = profile.phoneNumber
      }
      if (profile?.bankAccountNumber && !this.event.bankAccountNumber) {
        this.event.bankAccountNumber = profile.bankAccountNumber
      }
      if (profile?.bankAccountName && !this.event.bankAccountName) {
        this.event.bankAccountName = profile.bankAccountName
      }
    }
  },
  mounted() {
    if (!this.$store.state.event.eventItem.name) {
      this.$router.push(
        `/events/new/info${this.origin ? `?origin=${this.origin}` : ''}`
      )
    }
    this.$store.dispatch(OPTION_GET, 'payments_fee')
    if (Object.keys(this.profile).length <= 0) {
      this.$store.dispatch(PARTICIPANT_OWN)
    }
    // hide spinner
    this.$store.commit(EVENT_STATUS_EMPTY)
    // set data
    this.event = { ...this.eventItem }
    this.hasCosts = this.eventItem.hasCosts || 'no'
    if (this.profile?.bankAccountNumber) {
      this.event.bankAccountNumber = this.profile.bankAccountNumber
    }
    if (this.profile?.bankAccountName) {
      this.event.bankAccountName = this.profile.bankAccountName
    }
    if (this.profile?.phoneNumber) {
      this.phoneNumber = this.profile.phoneNumber
    }
    if (this.action === 'merchant_verification') {
      this.showMerchantModal = true
    }
  },
  methods: {
    formatCosts(val) {
      this.event.cost = val.replace(',', '.')
    },
    setHasCosts(val) {
      this.hasCosts = val
      this.$store.commit(EVENT_ITEM_SET, { key: 'hasCosts', value: val })
    },
    saveForm() {
      this.event.hasCosts = this.hasCosts
      this.$store.commit(EVENT_ITEM_SET_COSTS, this.event)
    },
    checkAndGoToReady: function () {
      this.$store.commit(EVENT_ITEM_SET_COSTS, this.event)
      if (this.paymentProvider === 'mollie' && this.hasCosts === 'yes') {
        this.$store.dispatch(PARTICIPANT_UPDATE_BANKACCOUNT, {
          bankAccountNumber: this.event.bankAccountNumber?.toUpperCase(),
          bankAccountName: this.event.bankAccountName
        })
      }
      this.$router.push(
        `/events/new/ready${this.origin ? `?origin=${this.origin}` : ''}`
      )
    },
    bankAccountNumberIsValid() {
      const regex = RegExp(/^NL\d{2}[A-Z]{4}\d{10}$/)
      return regex.test(this.event.bankAccountNumber)
    },
    checkBankAccountName() {
      setTimeout(() => {
        if (this.event.bankAccountName.length > 2) {
          this.bankAccountNameError = false
        } else {
          this.bankAccountNameError = true
        }
      }, 300)
    },
    checkBankAccountNumber() {
      setTimeout(() => {
        if (this.bankAccountNumberIsValid()) {
          this.bankAccountNumberError = false
        } else {
          this.bankAccountNumberError = true
        }
      }, 300)
    },
    phoneNumberIsValid() {
      const regex = RegExp(
        /^((\+|00(\s|\s?-\s?)?)31(\s|\s?-\s?)?(\(0\)[-\s]?)?|0)[1-9]((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]$/
      )
      return regex.test(this.phoneNumber)
    },
    storePhoneNumber() {
      setTimeout(() => {
        if (this.phoneNumberIsValid()) {
          this.phoneNumberError = false
          this.$store.dispatch(PARTICIPANT_UPDATE_PHONENUMBER, {
            phoneNumber: this.phoneNumber
          })
        } else {
          this.phoneNumberError = true
        }
      }, 300)
    },
    closeMerchantModal() {
      this.showMerchantModal = false
      this.$router.push(this.$route.path)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';

.cost-disclaimer {
  font-size: 0.875rem;
}
.flex {
  display: flex;
  margin-top: 1rem;
  align-items: center;
}
.desc {
  max-width: 400px;
  font-size: 1rem;
}
.amount {
  flex: 1;
  min-width: 80px;
  text-align: right;
  font-weight: bold;
  font-size: 1.125rem;
}
</style>
