<template>
  <div class="screen-organize-location-list screen-organize">
    <app-bar :title="pageTitle" :show-back-button="true" />
    <template v-if="locationStatus === 'loading'">
      <spinner-loader />
      <p class="loading-text">
        We zoeken nu in de database naar beschikbare locaties
      </p>
    </template>
    <template v-else>
      <div class="container">
        <h2 class="page-title">Beschikbare locaties in Alkmaar</h2>
        <h3 class="page-sub-title">(annuleer gratis tot 24 uur van tevoren)</h3>
        <div class="wrapper-location-list">
          <location-item
            v-for="location in locationList"
            :key="location.id"
            :name="location.name"
            :address="location.address"
            :cost="location.cost"
            :image="location.image"
            :entrance-instructions="location.entranceInstructions"
            :is-clickable="true"
            :description="location.description"
            :is-active="eventItemLocationID === location.id"
            :coordinates="location.coordinates"
            @click.native="selectLocation(location.id, true)"
          />
        </div>

        <div class="container nothing-found-container">
          <a
            href="#"
            class="btn btn-green btn-block margin-bottom-15"
            @click.prevent="routeToOrganizeLocationMap"
          >
            of kies een plek op kaart
          </a>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import LocationItem from '@/components/LocationItem'
import SpinnerLoader from '@/components/SpinnerLoader'
import {
  LOCATION_LIST,
  LOCATION_TYPED_LOCATIONS,
  LOCATION_TYPES_LOCATIONS_RESET
} from '@/store/actions/location'
import { EVENT_ITEM_SET_LOCATION } from '@/store/actions/event'
import { mapState } from 'vuex'
import moment from 'moment-timezone'

export default {
  name: 'OrganizeLocationList',
  components: {
    SpinnerLoader,
    AppBar,
    LocationItem
  },
  data() {
    const isForEdit = this.$route.params && this.$route.params.id !== undefined
    return {
      isForEdit,
      eventID: this.$route.params.id || null,
      pageTitle: isForEdit ? 'Event aanpassen' : 'Nieuwe activiteit 4 / 5'
    }
  },
  computed: {
    ...mapState({
      locationList: (state) => state.location.locationList,
      eventItemLocationID: (state) => state.event.eventItem.locationID,
      locationStatus: (state) => state.location.status
    })
  },
  beforeDestroy: function () {
    this.$store.dispatch(LOCATION_TYPES_LOCATIONS_RESET)
  },
  async mounted() {
    if (!this.hasAccessToScreen()) {
      if (this.isForEdit) {
        this.$router.push({
          path: `/event-edit/${this.eventID}/load`,
          query: {
            returnPage: 'location-list'
          }
        })
      } else {
        this.$router.push('/events/new/date')
      }
    }

    if (this.isForEdit) {
      const requestParams = {
        startTime: this.$store.state.event.eventEdit.startTime,
        endTime: this.$store.state.event.eventEdit.endTime,
        startDate: this.$store.state.event.eventEdit.startDate,
        endDate:
          this.$store.state.event.eventEdit.recurring !== 'none'
            ? this.$store.state.event.eventEdit.endDate
            : '',
        recurring:
          this.$store.state.event.eventEdit.recurring !== 'none'
            ? this.$store.state.event.eventEdit.recurring
            : 'none'
      }
      await this.$store.dispatch(LOCATION_TYPED_LOCATIONS, 2)
      await this.$store.dispatch(LOCATION_LIST, requestParams)
    } else {
      const requestParams = {
        startTime: moment
          .utc(this.$store.state.event.eventItem.startTime)
          .locale('nl')
          .set({ second: 0 })
          .format('HH:mm:ss'),
        endTime: moment
          .utc(this.$store.state.event.eventItem.endTime)
          .locale('nl')
          .set({ second: 0 })
          .format('HH:mm:ss'),
        startDate: this.$store.state.event.eventItem.startDate,
        endDate: this.$store.state.event.eventItem.isRecurring
          ? this.$store.state.event.eventItem.endDate
          : '',
        recurring: this.$store.state.event.eventItem.isRecurring
          ? this.$store.state.event.eventItem.recurring
          : 'none'
      }
      await this.$store.dispatch(LOCATION_TYPED_LOCATIONS, 2)
      await this.$store.dispatch(LOCATION_LIST, requestParams)
    }
  },
  methods: {
    hasAccessToScreen() {
      let hasAccess = false
      if (this.isForEdit) {
        if (
          this.$store.state.event.eventEdit &&
          !isNaN(this.$store.state.event.eventEdit.id)
        ) {
          hasAccess = true
        }
      } else {
        if (this.$store.state.event.eventItem.startDate !== null) {
          hasAccess = true
        }
      }
      return hasAccess
    },
    selectLocation: function (locationID, locationAvailable) {
      if (locationAvailable) {
        if (this.isForEdit) {
          this.$router.push(
            `/event-edit/${this.eventID}/location-details/${locationID}`
          )
        } else {
          this.$store.commit(EVENT_ITEM_SET_LOCATION, locationID)
          this.$router.push('/organize-location-details')
        }
      }
    },
    routeToOrganizeLocationMap: function () {
      if (this.isForEdit) {
        this.$router.push(`/event-edit/${this.eventID}/location-map`)
      } else {
        this.$router.push('/events/new/date')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';

.wrapper-location-list {
  padding-bottom: 75px;
}

.page-sub-title {
  font-size: 1rem;
  text-align: center;
}

.loading-text {
  padding: 0 30px;
  position: absolute;
  z-index: 1000;
  text-align: center;
  font-style: italic;
  margin-top: 130px;
  top: 50%;
  @include transform(translateY(-50%));
  width: 100%;
}

.nothing-found-container {
  height: 50px;
  position: fixed;
  bottom: 10px;
  left: 0;
  right: 0;
  background: $ipWhite;
}
</style>
