<template>
  <ul class="list-none p-0">
    <li
      v-for="item in items"
      :key="item.id"
      class="flex cursor-pointer my-3 items-center"
      @click.prevent="$emit('select-item', item.id)"
    >
      <RoundIcon
        :size="48"
        :border-size="2"
        :icon-class="item.icon"
        :is-active="selectedId === item.id"
        class="mr-6"
      />
      <span class="flex-1">{{ item.name }}</span>
      <i class="fas fa-chevron-right" />
    </li>
  </ul>
</template>

<script>
import RoundIcon from '@/components/RoundIcon'

export default {
  name: 'ItemList',
  components: { RoundIcon },
  props: {
    items: {
      type: Array,
      required: true
    },
    selectedId: {
      type: Number,
      required: false,
      default: undefined
    }
  }
}
</script>

<style lang="scss" scoped></style>
