<template>
  <div class="screen-organize-info screen-organize">
    <app-bar
      :title="
        event.groupId
          ? 'Nieuwe groepsactiviteit 3 / 5'
          : 'Nieuwe activiteit 5 / 7'
      "
      :show-back-button="true"
      :back-button-path="`/events/new/location${
        origin ? `?origin=${origin}` : ''
      }`"
      :show-profile-button="false"
    />
    <div class="container">
      <h1>Alle details</h1>

      <h2>Titel</h2>
      <input-field
        id="title"
        v-model="event.name"
        class="mt-2 mb-2"
        default-value="Verzin een leuke titel"
      />

      <h2>Beschrijving</h2>
      <text-field
        id="description"
        v-model="event.description"
        class="my-2"
        default-value="Vertel meer! Denk aan: geslacht, leeftijd, niveau, materialen en alles wat je verder nog wil delen."
        :rows="5"
      />

      <h2>Max aantal deelnemers</h2>
      <input-field
        id="maxParticipants"
        v-model="event.maximumParticipants"
        class="my-2"
        :default-value="0"
        type="number"
        pattern="[0-9]*"
        inputmode="numeric"
      />

      <h2 class="mt-6">Afbeelding</h2>
      <div class="flex mb-8 w-full items-stretch">
        <div class="w-24 h-24 sm:w-32 sm:h-32 mr-6">
          <div
            class="w-24 h-24 sm:w-32 sm:h-32 rounded-xl bg-cover bg-center relative mr-6"
            :style="{
              backgroundImage: `url('${computedImageUrl}')`
            }"
          />
        </div>
        <div class="flex flex-col flex-1 content-between">
          <p class="text-xs sm:text-sm flex-1">
            Upload een eigen afbeelding om de standaard afbeelding te vervangen.
          </p>

          <div class="flex justify-end">
            <button
              v-if="event.imageUrl"
              class="button button-danger button-sm w-full sm:w-1/2 sm:mr-2"
              @click="deleteImage"
            >
              Verwijderen
            </button>
            <div v-else class="w-full sm:w-1/2">
              <input
                id="event-image"
                type="file"
                class="opacity-0 overflow-hidden absolute -z-1 w-0 h-0"
                accept="image/*"
                @change="onFileChange"
              />
              <label
                for="event-image"
                class="button button-sm button-secondary button-icon my-2 hover:cursor-pointer p-0 mt-0 mb-0"
              >
                <span class="flex-1">Kies afbeelding</span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <button
          type="submit"
          class="button button-primary w-full"
          @click="sendForm"
        >
          Volgende
        </button>
      </div>
    </div>
    <spinner-loader v-if="eventLoading" />
  </div>
</template>
<script>
import AppBar from '@/components/AppBar'
import SpinnerLoader from '@/components/SpinnerLoader'
import InputField from '@/components/InputField'
import TextField from '@/components/TextField'
import { EVENT_ITEM_SET_INFO, EVENT_STATUS_EMPTY } from '@/store/actions/event'
import { mapState } from 'vuex'
import _ from 'lodash'
import noImage from '@/assets/images/img_no-image.png'
import { getOrientation } from '@/utils/getOrientation'
import api from '@/utils/api'
import config from '@/utils/config'

export default {
  name: 'OrganizeInfo',
  components: {
    AppBar,
    SpinnerLoader,
    InputField,
    TextField
  },
  data() {
    return {
      event: {
        image: undefined
      },
      imageUrl: undefined,
      origin: this.$route.query.origin,
      imageOrientation: undefined,
      selectedActivity: undefined
    }
  },
  computed: {
    ...mapState({
      eventLoading: (state) => state.event.status === 'loading',
      eventItem: (state) => state.event.eventItem
    }),
    computedImageUrl: function () {
      return this.imageUrl
        ? this.imageUrl
        : this.selectedActivity && this.selectedActivity.defaultImageUrl
        ? `${config.baseUrl}${this.selectedActivity.defaultImageUrl}`
        : noImage
    }
  },
  async created() {
    if (!this.$store.state.event.eventItem.locationID) {
      this.$router.push(
        `/events/new/location${this.origin ? `?origin=${this.origin}` : ''}`
      )
    }
    this.event = { ...this.eventItem }
    this.$store.commit(EVENT_STATUS_EMPTY)
    this.selectedActivity = await api
      .get(`/activity/${parseInt(this.event.activityID)}`)
      .then((resp) => resp.data)
  },
  methods: {
    onFileChange(e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }
      const file = files[0]
      const vm = this
      getOrientation(file, function (orientation) {
        if (this) {
          switch (orientation) {
            case 3:
              vm.imageOrientation = 'orientation-3'
              break
            case 6:
              vm.imageOrientation = 'orientation-6'
              break
            case 8:
              vm.imageOrientation = 'orientation-8'
              break
          }
        }
      })
      // Set image url in view
      const reader = new FileReader()
      reader.onload = (e) => {
        this.imageUrl = e.target.result
      }
      reader.readAsDataURL(file)
      this.event.image = file
    },
    deleteImage() {
      this.event.image = undefined
      this.imageUrl = undefined
    },
    saveForm() {
      this.$store.commit(EVENT_ITEM_SET_INFO, this.event)
    },
    sendForm: function () {
      if (this.isValid()) {
        this.$store.commit(EVENT_ITEM_SET_INFO, this.event)
        this.$router.push(
          `/events/new/invite${this.origin ? `?origin=${this.origin}` : ''}`
        )
      }
    },
    isValid: function () {
      let isValid = true
      let message = null

      // Check fields are valid
      if (_.isEmpty(this.event.name)) {
        isValid = false
        message = 'Titel is verplicht'
      } else if (_.isEmpty(this.event.description)) {
        isValid = false
        message = 'Omschrijving is verplicht'
      } else if (_.isEmpty(this.event.maximumParticipants)) {
        isValid = false
        message = 'Max. aantal deelnemers is verplicht'
      }

      if (message !== null) {
        alert(message)
      }

      return isValid
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';

.gender-select .item {
  float: left;
  width: 33%;
}
.input-file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.input-file + label {
  display: block;
}
.small-text {
  font-size: 0.875rem;
  color: $ipGreyLight;
}
</style>
