<template>
  <div class="screen-organize-activity screen-organize">
    <app-bar
      title="Nieuwe activiteit 2 / 7"
      :show-back-button="true"
      back-button-path="/events/new"
      :show-profile-button="false"
    />
    <div class="container">
      <h1>Wat voor organisator ben je?</h1>
      <ItemList
        v-if="formattedEventTypeList"
        :items="formattedEventTypeList"
        :selected-id="eventItemEventTypeID || undefined"
        @select-item="selectEventType"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import data from '@/utils/data'
import { EVENT_TYPE_LIST } from '@/store/actions/event_type'
import { EVENT_ITEM_SET_EVENT_TYPE } from '@/store/actions/event'
import AppBar from '@/components/AppBar'
import ItemList from '@/components/ItemList'

export default {
  name: 'OrganizeEventType',
  components: {
    ItemList,
    AppBar
  },
  data() {
    return {
      formattedEventTypeList: []
    }
  },
  computed: {
    ...mapState({
      eventTypeList: (state) => state.eventTypes.eventTypeList,
      eventItemEventTypeID: (state) => state.event.eventItem.eventTypeID
    })
  },
  watch: {
    eventTypeList(list) {
      this.formattedEventTypeList = list.map((item) => ({
        ...item,
        name: data.formatEventTypeItems(item.name)
      }))
    }
  },
  created: function () {
    this.$store.dispatch(EVENT_TYPE_LIST)
  },
  methods: {
    selectEventType: function (eventTypeId) {
      const vm = this
      this.$store.commit(EVENT_ITEM_SET_EVENT_TYPE, eventTypeId)
      setTimeout(function () {
        vm.routeToOrganizeActivity()
      }, 250)
    },
    routeToOrganizeActivity: function () {
      this.$router.push('/events/new/date')
    }
  }
}
</script>

<style lang="scss" scoped></style>
