var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"screen-organize"},[_c('app-bar',{attrs:{"title":_vm.event.groupId
        ? 'Nieuwe groepsactiviteit 4 / 5'
        : 'Nieuwe activiteit 6 / 7',"show-back-button":true,"back-button-path":("/events/new/info" + (_vm.origin ? ("?origin=" + _vm.origin) : '')),"show-profile-button":false}}),_c('div',{staticClass:"container"},[_c('h1',[_vm._v("Wie kan je activiteit zien?")]),_c('toggle',{attrs:{"title":"Iedereen kan deze activiteit zien op Inviplay"},on:{"toggle":function (val) {
          _vm.event.publicEvent = val
        }},model:{value:(_vm.event.publicEvent),callback:function ($$v) {_vm.$set(_vm.event, "publicEvent", $$v)},expression:"event.publicEvent"}}),_c('div',{staticClass:"flex align-middle items-center mt-8 mb-4"},[_c('h2',{staticClass:"my-0"},[_vm._v("Groepspagina")]),(!_vm.event.groupId)?_c('span',{staticClass:"ml-2 inline-block",on:{"click":function($event){_vm.showGroupInfoModal = true}}},[_c('icon-base',{staticClass:"mt-0 text-green-600",attrs:{"size":"24"}},[_c('icon-info',{attrs:{"stroke-width":2}})],1)],1):_vm._e()]),(_vm.event.groupId)?[_c('p',{staticClass:"text-small"},[_vm._v(" Je activiteit wordt toegevoegd aan je groepspagina "),_c('strong',[_vm._v(_vm._s(_vm.event.selectedGroupName))])])]:(!_vm.event.groupId && _vm.groupChoices && _vm.groupChoices.length)?[_c('toggle',{staticClass:"my-4",attrs:{"title":"Voeg activiteit toe aan een van mijn bestaande groepen"},on:{"toggle":function (val) {
            _vm.addToExistingGroup = val
          }},model:{value:(_vm.addToExistingGroup),callback:function ($$v) {_vm.addToExistingGroup=$$v},expression:"addToExistingGroup"}})]:_vm._e(),(
        _vm.addToExistingGroup &&
        !_vm.event.groupId &&
        _vm.groupChoices &&
        _vm.groupChoices.length
      )?[(
          _vm.addToExistingGroup &&
          !_vm.event.groupId &&
          _vm.groupChoices &&
          _vm.groupChoices.length
        )?_c('dropdown',{staticClass:"mb-4",attrs:{"items":_vm.groupChoices,"empty-option":"Kies een groep..."},on:{"on-select":_vm.updateSelectedGroup},model:{value:(_vm.event.selectedGroupId),callback:function ($$v) {_vm.$set(_vm.event, "selectedGroupId", $$v)},expression:"event.selectedGroupId"}}):_vm._e()]:(!_vm.groupChoices || !_vm.groupChoices.length || !_vm.addToExistingGroup)?[(!_vm.groupChoices || !_vm.groupChoices.length)?_c('p',{staticClass:"text-small mt-2"},[_vm._v(" Je hebt nog geen groepen aangemaakt op Inviplay, geef hieronder de naam van je groep waar we jouw activiteit kunnen plaatsen. ")]):_c('p',{staticClass:"text-small mt-8"},[_vm._v(" Geef hieronder de naam van je nieuwe groep waar we jouw activiteit kunnen plaatsen. ")]),_c('input-field',{staticClass:"mb-4 mt-4",attrs:{"default-value":"Naam van je groep"},on:{"input":_vm.updateNewGroupName},model:{value:(_vm.event.newGroupName),callback:function ($$v) {_vm.$set(_vm.event, "newGroupName", $$v)},expression:"event.newGroupName"}})]:_vm._e(),_c('h2',{staticClass:"mt-8"},[_vm._v("Uitnodigingen versturen")]),(
        !_vm.event.privateEvent ||
        (_vm.addToExistingGroup && (_vm.event.groupId || _vm.event.selectedGroupId))
      )?[(!_vm.event.privateEvent)?_c('toggle',{staticClass:"my-4",attrs:{"title":("Verstuur uitnodigingen naar iedereen bij jouw in de buurt die " + _vm.activity + " volgt")},on:{"toggle":function (val) {
            _vm.event.sendGeneralInvites = val
          }},model:{value:(_vm.event.sendGeneralInvites),callback:function ($$v) {_vm.$set(_vm.event, "sendGeneralInvites", $$v)},expression:"event.sendGeneralInvites"}}):_vm._e(),(_vm.addToExistingGroup && (_vm.event.selectedGroupId || _vm.event.groupId))?_c('toggle',{staticClass:"my-4",attrs:{"title":("Verstuur uitnodigingen naar iedereen in de groep '" + (_vm.event.selectedGroupName) + "'")},on:{"toggle":function (val) {
            _vm.event.sendGroupInvites = val
          }},model:{value:(_vm.event.sendGroupInvites),callback:function ($$v) {_vm.$set(_vm.event, "sendGroupInvites", $$v)},expression:"event.sendGroupInvites"}}):_vm._e()]:[_c('p',{staticClass:"text-sm my-4"},[_vm._v(" Dit is geen openbare activiteit, er worden geen uitnodigingen verstuurd ")])],_c('button',{staticClass:"button button-primary w-full mt-5",attrs:{"disabled":_vm.formInvalid},on:{"click":_vm.goToNextStep}},[_vm._v(" Volgende ")])],2),_c('modal',{attrs:{"show":_vm.showGroupInfoModal},on:{"close":function($event){_vm.showGroupInfoModal = false}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v("Groepspagina's")]},proxy:true}])},[_c('p',{staticClass:"text-sm"},[_vm._v(" Heb je nog geen groepspagina? Dan wordt er na het aanmaken van jouw activiteit automatisch een nieuwe groepspagina aangemaakt waar jouw activiteit op staat. Een volgende keer kan je makkelijk in dit scherm nieuwe activiteiten toevoegen door hier jouw bestaande groepspagina te kiezen. ")]),_c('p',{staticClass:"text-sm mt-4"},[_vm._v(" Mensen kunnen lid worden van jouw groep en zo in contact komen met jou (en anderen) door een berichtje te sturen en zich opgeven voor de activiteiten. ")]),_c('p',{staticClass:"text-sm mt-4"},[_vm._v(" Als je een strippenkaart koppelt aan jouw activiteiten kunnen mensen hem hier direct aanschaffen. ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }