<template>
  <div class="component-app-bar">
    <div :class="isIos || isAndroid ? 'mb-28' : 'mb-16'" />
    <!-- Download app on app store or play store -->
    <ios-button v-if="isIos" />
    <android-button v-else-if="isAndroid" />
    <div class="app-bar" :class="isIos || isAndroid ? 'top-16' : 'top-0'">
      <div class="app-bar-content container">
        <a
          v-if="showBackButton"
          href="#"
          class="app-bar-button left-1"
          @click.prevent="pressBackButton"
        >
          <icon-base size="20" label="Terug"><icon-chevron-left /></icon-base>
        </a>
        <p class="title">
          {{ title }}
        </p>
        <div
          v-if="showProfileButton"
          class="app-bar-button"
          :class="showContextMenu ? 'right-10' : 'right-2'"
          @click.prevent="routeToProfile"
        >
          <icon-base size="20" label="Profiel"><icon-profile /></icon-base>
        </div>
        <div
          v-if="showContextMenu"
          class="app-bar-button right-2"
          @click="$emit('toggle-context-menu')"
        >
          <icon-base size="20" label="Menu"><icon-menu /></icon-base>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconBase from '@/components/IconBase'
import IconChevronLeft from '@/components/icons/IconChevronLeft'
import IconProfile from '@/components/icons/IconProfile'
import IconMenu from '@/components/icons/IconMenu'
import IosButton from '@/components/IosButton'
import AndroidButton from '@/components/AndroidButton'

export default {
  name: 'AppBar',
  components: {
    IconBase,
    IconChevronLeft,
    IconProfile,
    IconMenu,
    IosButton,
    AndroidButton
  },
  props: {
    title: {
      type: String,
      required: false,
      default: function () {
        return null
      }
    },
    showBackButton: {
      type: Boolean,
      required: true
    },
    showProfileButton: {
      type: Boolean,
      required: false,
      default: true
    },
    showContextMenu: {
      type: Boolean,
      required: false,
      default: false
    },
    backButtonPath: {
      type: String,
      required: false,
      default: null
    }
  },
  data: () => ({
    platform: navigator.userAgentData?.platform || navigator.platform,
    iosPlatforms: ['iPhone', 'iPad', 'iPod'],
    androidPlatforms: ['Android']
  }),
  computed: {
    isIos() {
      return this.iosPlatforms.includes(this.platform)
    },
    isAndroid() {
      return this.androidPlatforms.includes(this.platform)
    }
  },
  mounted: function () {},
  methods: {
    pressBackButton() {
      if (this.backButtonPath !== undefined && this.backButtonPath !== null) {
        this.$router.push(this.backButtonPath)
      } else {
        this.$router.back()
      }
    },
    routeToProfile() {
      this.$router.push(
        `/profile-own${
          this.backButtonPath ? `?origin=${this.backButtonPath}` : ''
        }`
      )
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables';
.wrapper-circle {
  overflow: hidden;
  width: 185px;
  height: 135px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
}
.wrapper-circle .circle {
  width: 330px;
  height: 330px;
  border: 8px solid white;
  @include border-radius(330px);
  top: -195px;
  right: -165px;
  position: absolute;
}

.component-app-bar {
  z-index: 100;
  position: relative;
}
.component-app-bar .app-bar {
  background: $ipGreen;
  height: 48px;
  position: fixed;
  left: 0;
  right: 0;
}
.component-app-bar .app-bar .app-bar-content {
  padding: 0;
  position: relative;
  height: 45px;
}
.component-app-bar .app-bar .app-bar-content .back-button {
  color: $ipWhite;
  width: 45px;
  height: 45px;
  font-size: 26px;
  position: relative;
  display: inline-block;
  padding-left: 10px;
}
.component-app-bar .app-bar .app-bar-content .back-button i {
  width: 14px;
  height: 26px;
  position: absolute;
  top: 50%;
  @include transform(translateY(-50%));
}
.component-app-bar .app-bar .app-bar-content .title {
  font-size: 1.25rem;
  color: $ipWhite;
  text-align: center;
  position: absolute;
  font-weight: 600;
  top: 50%;
  left: 40px;
  right: 40px;
  @include transform(translateY(-50%));
  margin: 0;
  height: 30px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.app-bar-button {
  @apply absolute top-0 text-white h-8 w-8 my-1.5 flex items-center justify-center rounded-2xl hover:bg-white hover:bg-opacity-10 cursor-pointer;
}
@media (max-width: 340px) {
  .component-app-bar .app-bar .app-bar-content .title {
    font-size: 1rem;
    height: 25px;
  }
}
</style>
