<template>
  <div class="screen-organize-activity screen-organize">
    <app-bar
      title="Nieuwe activiteit 1 / 7"
      :show-back-button="true"
      back-button-path="/events"
      :show-profile-button="false"
    />
    <div class="container">
      <h1>Wat ga je doen?</h1>
      <ItemList
        v-if="activityList"
        :items="activityList"
        :selected-id="eventItemActivityID || undefined"
        @select-item="selectActivity"
      />
    </div>
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import ItemList from '@/components/ItemList'
import { mapState } from 'vuex'
import { ACTIVITY_LIST } from '@/store/actions/activity'
import { EVENT_ITEM_SET_ACTIVITY } from '@/store/actions/event'

export default {
  name: 'OrganizeActivity',
  metaInfo() {
    return {
      title: 'Organiseer een activiteit | Inviplay'
    }
  },
  components: {
    AppBar,
    ItemList
  },
  computed: {
    ...mapState({
      activityList: (state) => state.activity.activityList,
      eventItemActivityID: (state) => state.event.eventItem.activityID
    })
  },
  created: function () {
    this.$store.dispatch(ACTIVITY_LIST)
  },
  methods: {
    selectActivity: function (activityID) {
      const vm = this
      this.$store.commit(EVENT_ITEM_SET_ACTIVITY, activityID)
      setTimeout(function () {
        vm.$router.push('/events/new/type')
      }, 250)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
</style>
