<template>
  <div>
    <AppBar title="" :show-back-button="true"></AppBar>
    <SpinnerLoader v-if="loading"></SpinnerLoader>
    <div class="container">
      <h1>Wat wil je doen?</h1>
      <radio-button
        :selected="selectedType"
        label="Activiteit organiseren"
        name="type"
        value="event"
        @change="(val) => (selectedType = val)"
      >
        <p class="text-xs my-2 text-gray-500">
          Je wilt een activiteit organiseren op een specifieke datum of data
          waarvoor mensen zich kunnen aanmelden
        </p>
      </radio-button>
      <radio-button
        :selected="selectedType"
        label="Strippenkaart aanbieden"
        name="type"
        value="creditcard"
        @change="(val) => (selectedType = val)"
      >
        <p class="text-xs my-2 text-gray-500">
          Je wilt een strippenkaart aanbieden voor een aantal keer sporten. Je
          kunt deze strippenkaart koppelen aan bestaande of toekomstige
          activiteiten, maar ook aanbieden als je nog geen activiteit gepland
          hebt.
        </p>
      </radio-button>
      <button
        class="button button-primary w-full mt-8"
        :disabled="!selectedType"
        @click="
          selectedType === 'event'
            ? routeToOrganizeEvent()
            : routeToAddCreditcard()
        "
      >
        Volgende
      </button>
    </div>
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import SpinnerLoader from '@/components/SpinnerLoader'
import { mapState } from 'vuex'
import RadioButton from '../components/RadioButton.vue'

export default {
  name: 'AddEventOrCreditcard',
  components: { AppBar, SpinnerLoader, RadioButton },
  data() {
    return {
      loading: false,
      selectedType: undefined
    }
  },
  computed: {
    ...mapState({})
  },
  watch: {},
  created: function () {},
  methods: {
    routeToOrganizeEvent: function () {
      this.$router.push('/events/new')
    },
    routeToAddCreditcard: function () {
      this.$router.push('/cards/new')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
</style>
