<template>
  <div class="screen-organize-when screen-organize">
    <app-bar
      :title="
        event.groupId
          ? 'Nieuwe groepsactiviteit 1 / 5'
          : 'Nieuwe activiteit 3 / 7'
      "
      :show-back-button="true"
      :back-button-path="origin ? origin : '/events/new/type'"
      :show-profile-button="false"
    />
    <div class="container">
      <h1>Wanneer?</h1>

      <date-time-picker
        :disabled="false"
        title="Datum"
        :date-time="event.startDate"
        :start-date-time="event.startTime"
        :end-date-time="event.endTime"
        @change-date-time="changeDateTime"
      />

      <toggle
        v-model="isRecurring"
        title="Terugkerende activiteit"
        class="my-6"
        @toggle="
          (val) => {
            isRecurring = val
            event.endDate = undefined
            recurring = !val ? '7,day' : recurring
          }
        "
      />

      <div v-if="isRecurring">
        <dropdown
          v-model="recurring"
          :items="dataRecurring"
          :selected="recurring"
          class="mb-4"
          @on-select="(val) => (recurring = val)"
        />
        <h2>Tot en met</h2>
        <div class="relative">
          <datetime
            v-model="event.endDate"
            :disabled="!recurring"
            :class="recurring ? 'border-gray-600' : 'border-gray-300'"
            class="theme-green border h-11 rounded-lg p-1 px-4 mb-6"
            :format="dateFormat"
            input-class="w-100 h-100 text-left text-sm disabled:bg-white pl-6"
            :min-datetime="today"
            :minute-step="15"
            :phrases="{ ok: 'Ok', cancel: 'Annuleer' }"
          />
          <icon-base class="absolute left-3 top-3 text-gray-600" size="20">
            <icon-calendar />
          </icon-base>
        </div>
      </div>
      <button class="button button-primary w-full" @click="saveEvent">
        Volgende
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { DateTime } from 'luxon'
import AppBar from '@/components/AppBar'
import Toggle from '@/components/Toggle'
import Dropdown from '@/components/Dropdown'
import IconBase from '@/components/IconBase'
import IconCalendar from '@/components/icons/IconCalendar'
import DateTimePicker from '@/components/DateTimePicker'
import data from '@/utils/data'
import { EVENT_ITEM_SET_WHEN } from '@/store/actions/event'

export default {
  name: 'OrganizeWhen',
  components: {
    AppBar,
    DateTimePicker,
    Toggle,
    Dropdown,
    IconBase,
    IconCalendar
  },
  data() {
    return {
      event: {
        startDate: undefined,
        startTime: undefined,
        endDate: undefined,
        endTime: undefined
      },
      dateFormat: DateTime.DATE_HUGE,
      dataRecurring: data.eventRecurringChoices,
      isRecurring: false,
      recurring: '7,day',
      origin: this.$route.query.origin
    }
  },
  computed: {
    ...mapState({
      eventTypeList: (state) => state.eventTypes.eventTypeList,
      eventItemEventTypeID: (state) => state.event.eventItem.eventTypeID,
      eventItem: (state) => state.event.eventItem
    }),
    today() {
      return DateTime.local().toString()
    }
  },
  created() {
    if (!this.hasAccessToScreen()) {
      this.$router.push(`/events/new`)
    }
    this.event = { ...this.eventItem }
    this.isRecurring = this.eventItem.isRecurring
    this.recurring =
      !this.eventItem.recurring || !this.eventItem.recurring === 'none'
        ? '7,day'
        : this.eventItem.recurring
  },
  methods: {
    hasAccessToScreen() {
      let hasAccess = false
      if (this.$store.state.event.eventItem.activityID !== null) {
        hasAccess = true
      }
      return hasAccess
    },
    saveEvent: function () {
      if (this.isValid()) {
        this.event.isRecurring = this.isRecurring
        this.event.recurring = this.isRecurring ? this.recurring : null
        this.$store.commit(EVENT_ITEM_SET_WHEN, this.event)
        this.$router.push(
          `/events/new/location${this.origin ? `?origin=${this.origin}` : ''}`
        )
      }
    },
    isValid: function () {
      let isValid = true
      let message = null

      // Check fields are valid
      if (!this.event.startDate) {
        isValid = false
        message = 'Datum is verplicht'
      } else if (!this.event.startTime) {
        isValid = false
        message = 'Starttijd is verplicht'
      } else if (!this.event.endTime) {
        isValid = false
        message = 'Eindtijd is verplicht'
      } else if (
        DateTime.fromISO(this.event.endTime) <
        DateTime.fromISO(this.event.startTime)
      ) {
        isValid = false
        message = 'Eindtijd kan niet voor begintijd liggen'
      } else if (this.isRecurring) {
        if (!this.recurring) {
          isValid = false
          message = 'Terugkerend is verplicht'
        } else if (!this.event.endDate) {
          isValid = false
          message = 'Einddatum is verplicht'
        } else if (
          DateTime.fromISO(this.event.endDate) <
          DateTime.fromISO(this.event.startDate)
        ) {
          isValid = false
          message = 'Einddatum kan niet voor begindatum liggen'
        }
      }

      if (message !== null) {
        alert(message)
      }

      return isValid
    },
    changeDateTime(dateTimeObj) {
      this.event.startDate = dateTimeObj.dateTime
      this.event.startTime = dateTimeObj.startDateTime
      this.event.endTime = dateTimeObj.endDateTime
    },
    toggleRecurring(val) {
      this.eventIsRecurring = val
    }
  }
}
</script>
