<template>
  <div class="screen-organize-info screen-organize">
    <app-bar
      title="Voorbeeld van je event"
      :show-back-button="true"
      :back-button-path="`/events/new/costs${
        origin ? `?origin=${origin}` : ''
      }`"
      :show-profile-button="false"
    />
    <div class="container">
      <h1>Controleer je activiteit</h1>
      <p class="text-sm mb-6">
        Klopt alles? Druk dan op opslaan of ga terug om nog iets aan te passen
      </p>
      <div class="border rounded-lg p-4 pt-0">
        <h2>
          {{ event.name }}
        </h2>
        <event-item
          class="mt-4"
          :name="event.name"
          :activity-icon="event.activityIcon"
          :event-image="imageUrl"
          :cost="event.cost ? (event.cost + (fee ? fee : 0)).toString() : '0'"
          :start-time="eventStartTime"
          :end-time="eventEndTime"
          :date="eventStartDate"
          :location="location ? location : ''"
          :maximum-participants="parseFloat(event.maximumParticipants)"
          :show-name="false"
          :preview-event="true"
          :end-date="eventEndDate"
          :recurring="event.recurring"
          :private-event="event.private"
        />
        <h2>Details</h2>
        <div class="text-sm my-4">
          <nl2br tag="p" :text="event.description" />
          <p v-if="event.private">
            <em>
              Omdat dit een prive event is, verschijnt deze niet in de
              zoekresultaten
            </em>
          </p>
        </div>
      </div>

      <h2>Zichtbaarheid</h2>
      <p class="mt-4 text-sm">
        <template v-if="event.groupId">
          <template v-if="!event.privateEvent">
            Dit is een activiteit voor de groep {{ event.selectedGroupName }} en
            ook voor iedereen zichtbaar op Inviplay
          </template>
          <template v-else>
            Dit is een activiteit voor de groep {{ event.selectedGroupName }} en
            is niet zichtbaar op Inviplay
          </template>
        </template>
        <template v-else-if="event.selectedGroupId">
          <template v-if="!event.privateEvent">
            Deze activiteit is voor iedereen zichtbaar op Inviplay en wordt ook
            toegevoegd aan de groep
            <strong>{{ event.selectedGroupName }}</strong>
          </template>
          <template v-else>
            Deze activiteit is niet zichtbaar op Inviplay, maar wordt wel
            toegevoegd aan de groep
            <strong>{{ event.selectedGroupName }}</strong>
          </template>
        </template>
        <template v-else>
          <template v-if="!event.privateEvent">
            Iedereen kan deze activiteit zien op Inviplay
          </template>
          <template v-else>
            Dit is een privé activiteit en niet zichtbaar op Inviplay
          </template>
        </template>
      </p>

      <h2>Uitnodigen</h2>
      <p class="mt-4 text-sm">
        <template
          v-if="
            event.sendGeneralInvites &&
            event.sendGroupInvites &&
            event.selectedGroupName
          "
        >
          Wij nodigen iedereen uit bij jouw in de buurt die
          <strong>{{ activity }}</strong>
          volgt en iedereen uit de groep
          <strong>{{ event.selectedGroupName }}</strong>
          .
        </template>
        <template v-else-if="event.sendGeneralInvites">
          Wij nodigen iedereen uit bij jouw in de buurt die
          <strong>{{ activity }}</strong>
          volgt.
        </template>
        <template v-else-if="event.sendGroupInvites && event.selectedGroupName">
          Wij nodigen iedereen uit de groep
          <strong>{{ event.selectedGroupName }}</strong>
          uit.
        </template>
        <template v-else>Wij versturen geen uitnodigingen</template>
      </p>

      <div class="border-t mt-8">
        <h2>Telefoonnummer</h2>
        <p class="mt-4 text-sm">
          Op welk telefoonnummer kunnen we je bereiken? Dit wordt niet gedeeld
          met de deelnemers.
        </p>
        <div class="relative mt-6">
          <input-field
            v-model="event.phoneNumber"
            default-value="Telefoon nummer"
            @input="storePhoneNumber"
          />
          <i
            v-if="event.phoneNumber && !phoneNumberError"
            class="inline-icon fal fa-check"
          />
        </div>
        <div v-if="phoneNumberError" class="alert alert-danger mt-3">
          Dit geen correct telefoonnummer
        </div>
      </div>

      <button
        :disabled="buttonDisabled"
        class="button button-primary w-full my-6"
        @click="sendForm"
      >
        opslaan
      </button>
    </div>
    <SpinnerLoader v-if="loading" />
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import {
  EVENT_CREATE,
  EVENT_ITEM_SET_INFO,
  EVENT_STATUS_EMPTY
} from '@/store/actions/event'
import { mapState } from 'vuex'
import isDefinedType from 'is-defined-type'
import EventItem from '@/components/EventItem'
import InputField from '@/components/InputField'
import SpinnerLoader from '@/components/SpinnerLoader'
import { LOCATION_GET_BY_ID } from '@/store/actions/location'
import Nl2br from 'vue-nl2br'
import { DateTime } from 'luxon'
import { OPTION_GET } from '@/store/actions/option'
import { GROUP_GET_ALL } from '@/store/actions/group'
import {
  PARTICIPANT_OWN,
  PARTICIPANT_UPDATE_PHONENUMBER
} from '@/store/actions/participant'
import { isValid } from '@/utils/validation'
import noImage from '@/assets/images/img_no-image.png'
import api from '@/utils/api'
import config from '@/utils/config'
import { ACTIVITY_LIST } from '../../store/actions/activity'

export default {
  name: 'OrganizeReady',
  components: {
    EventItem,
    SpinnerLoader,
    AppBar,
    Nl2br,
    InputField
  },
  data() {
    return {
      event: {},
      phoneNumberError: undefined,
      origin: this.$route.query.origin,
      selectedActivity: undefined
    }
  },
  computed: {
    ...mapState({
      eventItem: (state) => state.event.eventItem,
      locationDetails: (state) => state.location.details,
      allGroups: (state) => state.group.allGroups,
      fee: (state) => state.option.options.payments_fee,
      loading: (state) => state.event.status === 'loading',
      profile: (state) => state.participant.ownProfile,
      activityKeyValueList: (state) => state.activity.activityKeyValueList
    }),
    activity: function () {
      let sport
      if (this.activityKeyValueList.length > 0) {
        sport = this.activityKeyValueList.find(
          (a) => a.key === parseInt(this.event.activityID)
        )
      }
      return sport ? sport.value : undefined
    },
    location: function () {
      return (
        this.locationDetails &&
        `${this.locationDetails.adress}, ${this.locationDetails.city}`
      )
    },
    imageUrl: function () {
      return this.event.image && typeof this.event.image.name === 'string'
        ? URL.createObjectURL(this.event.image)
        : this.selectedActivity && this.selectedActivity.defaultImageUrl
        ? `${config.baseUrl}${this.selectedActivity.defaultImageUrl}`
        : noImage
    },
    eventStartDate: function () {
      return this.event && this.event.startDate
        ? DateTime.fromISO(this.event.startDate).toFormat('ccc d MMM')
        : null
    },
    eventStartTime: function () {
      return this.event && this.event.startTime
        ? DateTime.fromISO(this.event.startTime).toFormat('HH:mm')
        : null
    },
    eventEndTime: function () {
      return this.event && this.event.endTime
        ? DateTime.fromISO(this.event.endTime).toFormat('HH:mm')
        : null
    },
    eventEndDate: function () {
      return this.event && this.event.endDate
        ? DateTime.fromISO(this.event.endDate).toFormat('ccc d MMM')
        : null
    },
    buttonDisabled: function () {
      return this.phoneNumberError || !this.event.phoneNumber
    }
  },
  watch: {
    allGroups(groups) {
      if (this.event.groupId) {
        const selectedGroup = groups.data.find(
          (group) => group.id === this.event.groupId
        )
        this.event.selectedGroupName = selectedGroup ? selectedGroup.name : null
      }
    },
    'profile.phoneNumber'(phoneNumber) {
      if (!this.event.phoneNumber) {
        this.event.phoneNumber = phoneNumber
      }
    }
  },
  async created() {
    if (!this.$store.state.event.eventItem.name) {
      this.$router.push(
        `/events/new/info${this.origin ? `?origin=${this.origin}` : ''}`
      )
    }
    this.event = { ...this.eventItem }
    this.$store.commit(EVENT_STATUS_EMPTY)
    this.$store.dispatch(LOCATION_GET_BY_ID, this.event.locationID)
    if (!this.fee) {
      this.$store.dispatch(OPTION_GET, 'payments_fee')
    }
    if (this.eventItem.groupId && !this.eventItem.selectedGroupName) {
      this.$store.dispatch(GROUP_GET_ALL, {})
    }
    if (Object.keys(this.profile).length <= 0) {
      this.$store.dispatch(PARTICIPANT_OWN)
    }
    if (this.profile && this.profile.phoneNumber) {
      if (!this.event.phoneNumber) {
        this.event.phoneNumber = this.profile.phoneNumber
      }
    }
    if (!this.activityKeyValueList.length) {
      this.$store.dispatch(ACTIVITY_LIST)
    }
    this.selectedActivity = await api
      .get(`/activity/${parseInt(this.event.activityID)}`)
      .then((resp) => resp.data)
  },
  methods: {
    storePhoneNumber() {
      setTimeout(() => {
        if (isValid.phoneNumber(this.event.phoneNumber)) {
          this.phoneNumberError = false
          this.$store.dispatch(PARTICIPANT_UPDATE_PHONENUMBER, {
            phoneNumber: this.event.phoneNumber
          })
          this.$store.commit(EVENT_ITEM_SET_INFO, this.event)
        } else {
          this.phoneNumberError = true
        }
      }, 250)
    },
    sendForm: function () {
      this.$store
        .dispatch(EVENT_CREATE)
        .then((response) => {
          if (!isDefinedType(response, 'data.id')) {
            alert(
              'Er is iets fout gegaan (1): ' +
                isDefinedType(response, 'response.data.error.message', [
                  'string',
                  'value'
                ])
            )
          } else {
            this.routeToEventView(response.data.id, response.data.securityHash)
          }
        })
        .catch((e) => {
          alert('Er is iets fout gegaan (2): ' + e)
        })
    },
    routeToEventView: function (id, securityHash) {
      this.$router.push('/event-view/' + id + '/' + securityHash)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
.title {
  font-size: 1.25rem;
  text-align: center;
}
.subtitle {
  text-align: center;
  margin: 1rem 0;
}
.eventContainer {
  border: 1px solid grey;
  border-radius: 4px;
  padding: 1rem;
}
.relative {
  position: relative;
}
.inline-icon {
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: $ipGreen;
}
</style>
