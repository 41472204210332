<template>
  <div>
    <app-bar
      :title="
        event.groupId
          ? 'Nieuwe groepsactiviteit 2 / 5'
          : 'Nieuwe activiteit 4 / 7'
      "
      :show-back-button="true"
      :back-button-path="`/events/new/date${origin ? `?origin=${origin}` : ''}`"
      :show-profile-button="false"
    />
    <div class="container text-sm">
      <h1>Kies locatie</h1>
      <div v-if="selectedLocation">
        <h2>Gekozen locatie</h2>
        <div class="flex mb-2">
          <div class="mr-4">
            <icon-base><icon-home /></icon-base>
          </div>
          <div>{{ selectedLocation.name }}</div>
        </div>
        <div class="flex">
          <div class="mr-4">
            <icon-base><icon-map /></icon-base>
          </div>
          <div>{{ selectedLocation.adress }}, {{ selectedLocation.city }}</div>
        </div>
        <div class="mt-4">
          <button
            class="button button-secondary w-full mt-4"
            @click="resetLocation"
          >
            Kies andere locatie
          </button>
          <button class="button button-primary w-full mt-4" @click="goToNext">
            Volgende
          </button>
        </div>
      </div>
      <location-picker
        v-else
        :previous-locations="previousLocations"
        :is-loading="locationIsLoading"
        @set-previous-location="setPreviousLocation"
        @save-location="saveLocation"
        @reload-locations="reloadPreviousLocations"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AppBar from '@/components/AppBar'
import LocationPicker from '@/components/LocationPicker'
import IconBase from '@/components/IconBase'
import IconMap from '@/components/icons/IconMap'
import IconHome from '@/components/icons/IconHome'
import { LOCATION_BY_USER_ID } from '@/store/actions/location'
import { LOCATION_CREATE } from '@/store/actions/location'
import { LOCATION_GET_BY_ID } from '@/store/actions/location'
import { EVENT_ITEM_SET_LOCATION } from '@/store/actions/event'

export default {
  name: 'OrganizeLocation',
  components: {
    AppBar,
    LocationPicker,
    IconBase,
    IconMap,
    IconHome
  },
  props: {},
  data() {
    return {
      event: {},
      locationIsLoading: false,
      selectedLocation: null,
      origin: this.$route.query.origin
    }
  },
  computed: {
    ...mapState({
      eventItem: (state) => state.event.eventItem,
      previousLocations: (state) => state.location.previousLocations,
      locationDetails: (state) => state.location.details
    })
  },
  watch: {
    event(event) {
      if (event && event.locationID && !this.selectedLocation) {
        this.$store.dispatch(LOCATION_GET_BY_ID, event.locationID)
      }
    },
    locationDetails(location) {
      if (location) {
        this.selectedLocation = { ...location }
      }
    }
  },
  mounted() {
    if (!this.hasAccessToScreen()) {
      this.$router.push(
        `/events/new/date${this.origin ? `?origin=${this.origin}` : ''}`
      )
    }
    this.groupId = this.eventItem.groupID
    this.event = { ...this.eventItem }
  },
  created() {
    if (!this.previousLocations || !this.previousLocations.length > 0) {
      this.$store.dispatch(LOCATION_BY_USER_ID)
    }
  },
  methods: {
    hasAccessToScreen() {
      let hasAccess = false
      if (this.$store.state.event.eventItem.startDate !== null) {
        hasAccess = true
      }
      return hasAccess
    },
    setPreviousLocation(location) {
      this.selectedLocation = location
      this.event.locationID = location.id
    },
    resetLocation() {
      this.selectedLocation = null
      this.event.locationID = null
    },
    saveLocation: async function (location) {
      this.locationIsLoading = true
      this.$store.dispatch(LOCATION_CREATE, location).then(({ data }) => {
        this.event.locationID = data.id
        this.locationIsLoading = false
        this.selectedLocation = data
      })
    },
    goToNext() {
      this.$store.commit(EVENT_ITEM_SET_LOCATION, this.event.locationID)
      this.$router.push(
        `/events/new/info${this.origin ? `?origin=${this.origin}` : ''}`
      )
    },
    reloadPreviousLocations() {
      this.$store.dispatch(LOCATION_BY_USER_ID)
    }
  }
}
</script>

<style lang="scss" scoped></style>
