<template>
  <modal :show="show" show-close-icon @close="$emit('close')">
    <template v-slot:header>ID Check</template>
    <div>
      <p>
        <strong>Meer over de ID Check:</strong>
        <br />
        Om betalingen af te handelen tussen partijen moet een organisatie een
        banklicentie hebben. Inviplay heeft zelf geen banklicentie en werkt
        daarom samen met Online Payment Platform. Online Payment Platform heeft
        namelijk wèl een licentie als betaaldienstverlener van De Nederlandsche
        Bank. Zij verwerken betalingen voor honderden bedrijven, waaronder eBay,
        Marktplaats en dus ook voor Inviplay.
      </p>

      <p class="mt-4">
        <strong>Know your customer</strong>
        <br />
        Om uitbetalingen te mogen doen is Online Payment Platform wettelijk
        verplicht om de identiteit te kennen van de mensen aan wie zij geld
        uitkeren, net als elke bank. Dat heet KYC (“know your customer”).
        Vandaar dat zij een kopie van jouw ID nodig hebben en die mogen
        verwerken (in tegenstelling tot veel andere bedrijven). In plaats van
        het uploaden van een ID kan de ID check ook door eenmalig €0,10 over te
        maken naar Online Payment Platform.
      </p>

      <p class="mt-4">
        <strong>Wat gebeurt er met jouw gegevens?</strong>
        Inviplay slaat jouw ID niet zelf op. Online Payment Platform is
        gespecialiseerd in het verwerken van gevoelige informatie. Zij
        versleutelen de gegevens en bewaren deze op extra beveiligde servers. Je
        gegevens worden alleen gebruikt om je identiteit vast te stellen. Zie
        ook hun beleid:
        <a
          href="https://onlinepaymentplatform.com/nl/privacy"
          target="_blank"
          class="link"
        >
          https://onlinepaymentplatform.com/nl/privacy
        </a>
        en
        <a
          href="https://onlinepaymentplatform.com/nl/veiligheid"
          target="_blank"
          class="link"
        >
          https://onlinepaymentplatform.com/nl/veiligheid
        </a>
        .
      </p>
    </div>
  </modal>
</template>

<script>
import Modal from '@/components/Modal.vue'

export default {
  name: 'IdCheckInfoModal',
  components: {
    Modal
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  created: function () {},
  methods: {}
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
</style>
