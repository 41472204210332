<template>
  <div class="screen-organize-location-map screen-organize">
    <app-bar
      :title="pageTitle"
      :show-back-button="true"
      :show-profile-button="false"
    />
    <div class="container">
      <h2 class="page-title">Waar?</h2>
    </div>
    <div class="container no-padding wrapper-map">
      <google-maps-location-select ref="mapsLocationSelect" />
    </div>
    <div class="container form-container">
      <form @submit.prevent="searchAddressOnMap">
        <div class="row row-search-box">
          <div class="col-10">
            <div class="form-group">
              <input
                v-model="searchAddress"
                placeholder="Zoek op adres"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-2">
            <button class="btn btn-block btn-green btn-search-text btn-round">
              <i class="fas fa-search" />
              <br />
            </button>
          </div>
        </div>
      </form>
      <form @submit.prevent="openConfirmModal">
        <div class="form-group">
          <button class="btn btn-green btn-block btn-save btn-round">
            ga verder
          </button>
        </div>
      </form>
    </div>
    <transition name="modal">
      <template v-if="confirmModal">
        <div id="modal">
          <div class="content container">
            <div class="close-button" @click.prevent="closeModal">
              <i class="fal fa-times" />
            </div>

            <h2 class="page-title">Controleer je adres</h2>

            <hr />

            <p v-if="addressObject">
              <strong>
                {{ addressObject.address }} {{ addressObject.streetNumber }}
              </strong>
              <br />
              <strong>
                {{ addressObject.postalCode }} {{ addressObject.locality }}
              </strong>
            </p>
            <p>
              (onjuist adres? ga dan terug, zoek opnieuw of verplaats de groene
              pin)
            </p>

            <hr />

            <form @submit.prevent="saveForm">
              <div class="form-group">
                <input
                  v-model="location.name"
                  placeholder="Geef naam locatie"
                  class="form-control"
                />
              </div>

              <p>(Deze naam staat straks bij je event informatie)</p>

              <div class="form-group">
                <button class="btn btn-green btn-block btn-save btn-round">
                  opslaan
                </button>
              </div>
            </form>

            <div class="form-group">
              <button
                class="btn btn-white-border-green btn-block btn-save btn-round"
                @click.prevent="closeModal"
              >
                corrigeren
              </button>
            </div>
          </div>
        </div>
      </template>
    </transition>
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import GoogleMapsLocationSelect from '@/components/GoogleMapsLocationSelect'
import {
  EVENT_UPDATE_LOCATION,
  LOCATION_CREATE
} from '@/store/actions/location'
import _ from 'lodash'
import { googleSearchLongAndLatOnMap } from '@/utils/api'

export default {
  name: 'OrganizeLocationMap',
  components: {
    AppBar,
    GoogleMapsLocationSelect
  },
  data() {
    const isForEdit = this.$route.params && this.$route.params.id !== undefined
    return {
      isForEdit,
      eventID: this.$route.params.id || null,
      pageTitle: this.$store.state.event.eventItem.groupId
        ? 'Nieuwe groepsactiviteit 2 / 5'
        : isForEdit
        ? 'Event aanpassen'
        : 'Nieuwe activiteit 4 / 7',
      confirmModal: false,
      searchAddress: null,
      addressObject: null,
      location: {
        name: null
      }
    }
  },
  created() {
    if (!this.hasAccessToScreen()) {
      if (this.isForEdit) {
        this.$router.push({
          path: `/event-edit/${this.eventID}/load`,
          query: {
            returnPage: 'location-map'
          }
        })
      } else {
        this.$router.push('/events/new/date')
      }
    }
  },
  methods: {
    openConfirmModal() {
      // Clear the search address and open the model
      this.$refs.mapsLocationSelect
        .getPositionData()
        .then((addressObject) => {
          if (
            addressObject.address &&
            addressObject.country &&
            addressObject.locality &&
            addressObject.postalCode &&
            addressObject.streetNumber
          ) {
            // We have a valid address object set it and show the confirm popup
            this.addressObject = addressObject
            this.searchAddress = null
            this.confirmModal = true
          } else {
            // We do not have a valid address object
            // This can be due to a not valid picker location
            alert(
              'Adres is incompleet, verplaats the marker om een goed adres te vinden'
            )
          }
        })
        .catch(() => {
          // General error, show the message to the user
          alert('Er is iets mis gegaan met het ophalen adres gegevens')
        })
    },
    closeModal() {
      this.confirmModal = false
    },
    async searchAddressOnMap() {
      const location = await googleSearchLongAndLatOnMap(this.searchAddress)
      this.$refs.mapsLocationSelect.setLocation(location)
    },
    hasAccessToScreen() {
      let hasAccess = false
      if (this.isForEdit) {
        if (
          this.$store.state.event.eventEdit &&
          !isNaN(this.$store.state.event.eventEdit.id) &&
          !isNaN(this.$store.state.event.eventEdit.locationID)
        ) {
          hasAccess = true
        }
      } else {
        if (this.$store.state.event.eventItem.startDate !== null) {
          hasAccess = true
        }
      }
      return hasAccess
    },
    saveForm: function () {
      const vm = this
      if (vm.isValid()) {
        // Add name to object
        vm.addressObject.name = vm.location.name
        vm.$store
          .dispatch(LOCATION_CREATE, vm.addressObject)
          .then((res) => res.data)
          .then((location) => {
            if (this.isForEdit) {
              this.$store
                .dispatch(EVENT_UPDATE_LOCATION, {
                  eventID: this.$route.params.id,
                  locationID: location.id
                })
                .then((res) => {
                  if (res) {
                    this.$router.push(`/event-view/${this.$route.params.id}`)
                  } else {
                    alert('Locatie kon niet worden aangepast')
                  }
                })
            } else {
              vm.routeToOrganizeInfo()
            }
          })
          .catch(() => {
            alert('Er is iets fout gegaan: locatie is niet toegevoegd')
          })
      }
    },
    isValid: function () {
      let isValid = true
      let message = null

      // Check fields are valid
      if (_.isEmpty(this.location.name)) {
        isValid = false
        message = 'Omschrijving locatie is verplicht'
      }
      if (message !== null) {
        alert(message)
      }

      return isValid
    },
    routeToOrganizeLocationList: function () {
      this.$router.push('/events/new/info')
    },
    routeToOrganizeInfo: function () {
      this.$router.push('/events/new/info')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';

.wrapper-map {
  position: fixed;
  left: 0;
  right: 0;
  top: 120px;
  bottom: 140px;
  z-index: -1;
}

.form-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.form-container .form-group {
  margin-bottom: 10px;
}

#modal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 200;
  transition: opacity 0.3s ease;

  .content {
    background-color: white;
    padding: 10px 40px;
    margin-top: 35px;
    text-align: center;
    position: relative;
    transition: all 0.3s ease;

    .close-button {
      position: absolute;
      top: 0;
      right: 0;
      height: 50px;
      width: 50px;

      i.fal {
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: 18px;
        transform: translate(-50%, -50%);
      }
    }
  }
}

// Transition effect
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .content,
.modal-leave-active .content {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.row-search-box .col-10 {
  padding-right: 2px;
}

.row-search-box .col-2 {
  padding-left: 2px;
}

.btn-search-text {
  padding-left: 0;
  padding-right: 0;
  text-align: center;
}
</style>
