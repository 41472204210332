<template>
  <div
    class="flex items-center h-16 overflow-hidden box-border p-3 border-b border-gray-200 fixed top-0 left-0 right-0 z-50 bg-white"
  >
    <div class="w-14 h-full">
      <a
        href="https://play.google.com/store/apps/details?id=nl.inviplay.app.prd"
        class="no-underline"
        target="_blank"
      >
        <img class="max-h-full" src="@/assets/images/app-icon.png" />
      </a>
    </div>
    <div class="flex-1 flex flex-col justify-evenly">
      <p class="text-xs font-bold font-device">Inviplay</p>
      <p class="text-xs font-device">Gratis app voor Android</p>
    </div>
    <div class="">
      <a
        href="https://play.google.com/store/apps/details?id=nl.inviplay.app.prd"
        class="border border-green-600 rounded-md px-4 py-2 text-xs font-bold font-device text-green-600 no-underline"
        target="_blank"
      >
        DOWNLOADEN
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IosButton',
  props: {},
  data() {
    return {}
  },
  created: function () {},
  methods: {}
}
</script>
