<template>
  <div class="overlay">
    <div class="spinner-loader" />
  </div>
</template>

<script>
export default {
  name: 'SpinnerLoader'
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables';
.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 200;
}

.spinner-loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid $ipGreen;
  border-bottom: 16px solid $ipGreen;
  width: 80px;
  height: 80px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  @include transform(translateY(-50%));
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
