<template>
  <div class="border rounded-lg border-gray-300 p-4 mb-8 mt-8">
    <p class="text-sm">
      Inviplay keert betalingen wekelijks automatisch uit op jouw bankrekening.
      Bij elke betaling ontvang je een berichtje, en de uitgekeerde bedragen
      vind je terug in jouw profiel. Om uitbetalingen te kunnen doen van jouw
      activiteiten of strippenkaarten hebben we uiteraard jouw bankgegevens
      nodig en zijn we wettelijk verplicht jouw ID te verifiëren. Daarom vragen
      we je deze gegevens te bevestigen via een ID check.
      <br />
      <br />
      <a href="#" class="link" @click.prevent="showInfoModal = true">
        Lees hier meer over de ID check
      </a>
      .
    </p>

    <button
      class="button button-primary w-full mt-8"
      @click="
        $router.push({
          name: 'IDCheck',
          query: { redirectTo }
        })
      "
    >
      Start de ID check
    </button>
    <id-check-info-modal :show="showInfoModal" @close="showInfoModal = false" />
  </div>
</template>

<script>
import IdCheckInfoModal from '@/components/IdCheckInfoModal.vue'

export default {
  name: 'IdCheckComponent',
  components: {
    IdCheckInfoModal
  },
  props: {
    redirectTo: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showInfoModal: false
    }
  },
  created: function () {},
  methods: {}
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
</style>
